import {makeStyles} from '@mui/styles';

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    flexDirection: 'column',
    padding: '20px 0'
  },
  input: {
    width: '80%',
    margin: '10px auto'
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
}));
