import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import {useStyles} from './styles'

const Login = ({ email, setEmail, password, setPassword, submitLogin }) => {
  const classes = useStyles()

  return (
    <>
      <Grid container justifyContent={"center"} alignItems="center">
        <Grid item sm={6}>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h1" align={'center'} style={{marginTop: '10px'}}>
              Login
            </Typography>
            <form className={classes.root} method={'post'} noValidate autoComplete="off" onSubmit={submitLogin}>
              <FormControl className={classes.formControl}>
                <TextField
                  value={email}
                  className={classes.input}
                  label="Email"
                  onChange={e => {setEmail(e.target.value)}}
                  autoFocus
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  value={password}
                  className={classes.input}
                  type={'password'}
                  label="Password"
                  onChange={e => {setPassword(e.target.value)}}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Button
                  className={classes.input}
                  type={'submit'}
                >
                  Anmelden
                </Button>
              </FormControl>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

Login.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  submitLogin: PropTypes.func,
}

export default Login;
