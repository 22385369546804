import React, {useState} from 'react';
import Login from './Login'
import {useLogin} from "./LoginContext";
import {getToken} from "../../lib/oauth2";
import {useNotificationPush} from "../Snackbar/SnackbarContext";

const LoginProvider = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { setLoggedIn } = useLogin()
  const { pushSuccess, pushError } = useNotificationPush()

  const submitLogin = async (e) => {
    e.preventDefault()

    const token = await getToken(email, password)
    if (token && !token.error) {
      const time = Math.round(new Date().getTime() / 1000)
      const expiredTimestamp = time + parseInt(token.expires_in, 10)

      localStorage.setItem('token', token.access_token);
      localStorage.setItem('refreshToken', token.refresh_token);
      localStorage.setItem('expiredTimestamp', expiredTimestamp.toString());
      setLoggedIn(true)
      pushSuccess('Anmeldung erfolgreich')

      return token
    } else if (token.error) {
      pushError(token.error.message)
    }

    localStorage.clear()
    setLoggedIn(false)
    return false
  }

  return (
    <>
      <Login
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        submitLogin={submitLogin}
      />
    </>
  );
}

LoginProvider.propTypes = {}

export default LoginProvider;
