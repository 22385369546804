import React from 'react';
// import PropTypes from 'prop-types';
import Login from "../features/Login";
import {Container} from "@mui/material";

const LoginPage = () => {
  return (
    <Container maxWidth={"lg"} style={{marginTop: '60px'}}>
      <Login />
    </Container>
  );
}

LoginPage.propTypes = {}

export default LoginPage;
