export const WEEKEND_URL_SEGMENT = 'wochenende'
export const WEEKDAY_URL_SEGMENT = 'wochenplan'

export const CREATE_USER_PERMISSION = 'CREATE_USER'
export const WEEKDAY_EDIT_PERMISSION = 'WEEKDAY_EDIT'
export const RESET_PASSWORD_PERMISSION = 'RESET_PASSWORD'

export const EVENTTYPE_MAPPING = {
  RegularGameOperation: 'Regulärer Spielbetrieb',
  Extraordinary: 'Besondere Veranstaltung'
}

export const EXPECTED_AUDIENCE_MAPPING = {
  Less100: {short: '<100', long: 'unter 100'},
  Less250: {short: '100 bis 250', long: '100 bis 250'},
  Less500: {short: '250 bis 500', long: '250 bis 500'},
  More500: {short: '500+', long: 'über 500'},
}
