import {makeOperation} from '@urql/core';
import {authExchange} from '@urql/exchange-auth';
import {refresh} from "../../oauth2";

const exchange = authExchange({
  addAuthToOperation: ({ authState, operation,}) => {
    // the token isn't in the auth state, return the operation without changes
    if (!authState || !authState.token) {
      return operation;
    }

    // fetchOptions can be a function (See Client API) but you can simplify this based on usage
    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(
      operation.kind,
      operation,
      {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            "Authorization": `Bearer ${authState.token}`,
          },
        },
      },
    );
  },
  willAuthError: ({ authState }) => {
    if (!authState) return true;

    // e.g. check for expiration, existence of auth etc
    const currTime = Math.round(new Date().getTime() / 1000)
    const tokenEndOfLife = localStorage.getItem('expiredTimestamp')

    return currTime > tokenEndOfLife
  },
  didAuthError: ({ error }) => {
    // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
    return error.graphQLErrors.some(
      e => e.message.includes('The resource owner or authorization server denied the request')
    );
  },
  getAuth: async ({ authState, mutate }) => {
    // fix wrong state when loggin in after logout
    if (authState) {
      if (authState.token !== localStorage.getItem('token') || authState.refreshToken !== localStorage.getItem('refreshToken')) {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        if (token && refreshToken) {
          return { token, refreshToken };
        }
      }
    }

    // for initial launch, fetch the auth state from storage (local storage, async storage etc)
    if (!authState) {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      if (token && refreshToken) {
        return { token, refreshToken };
      }
      return null;
    }

    /**
     * the following code gets executed when an auth error has occurred
     * we should refresh the token if possible and return a new auth state
     * If refresh fails, we should log out
     **/

    // if your refresh logic is in graphQL, you must use this mutate function to call it
    // if your refresh logic is a separate RESTful endpoint, use fetch or similar
    const refreshToken = await refresh(authState.refreshToken)

    if (refreshToken) {
      const time = Math.round(new Date().getTime() / 1000)
      const expiredTimestamp = time + parseInt(refreshToken.expires_in, 10)

      // save the new tokens in storage for next restart
      localStorage.setItem('token', refreshToken.access_token);
      localStorage.setItem('refreshToken', refreshToken.refresh_token);
      localStorage.setItem('expiredTimestamp', expiredTimestamp.toString());

      // return the new tokens
      return {
        token: refreshToken.access_token,
        refreshToken: refreshToken.refresh_token,
      };
    }

    // otherwise, if refresh fails, log clear storage and log out
    localStorage.clear();

    // const [, setLocation] = useLocation();

    // your app logout logic should trigger here
    window.location('/logout/')

    return null;
  },
})

export default exchange
