import React, {Suspense, useEffect} from 'react';

// import {useRoutes, usePath, navigate} from 'hookrouter';
import {Route, Switch, useLocation, useRoute} from "wouter";
import CircularProgress from '@mui/material/CircularProgress';

import Logout from "./pages/Logout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import LoginContext from "./features/Login/LoginContext";
import MyselfContext from "./context/MyselfContext";

import Layout from "./layout";
import {Provider as URQLProvider} from "urql";
import GraphQlClient from "./lib/graphql/URQLClientSilverstripe";
import {WEEKDAY_URL_SEGMENT, WEEKEND_URL_SEGMENT} from "./config/constants";

const AcceptedBookings = React.lazy(() => import('./pages/AcceptedBookings'))
const Bookings = React.lazy(() => import('./pages/Bookings'))
const RequestedBookings = React.lazy(() => import('./pages/RequestedBookings'))
const Settings = React.lazy(() => import('./pages/Settings'))
const UserList = React.lazy(() => import('./pages/UserList'))
const Trainer = React.lazy(() => import('./pages/Trainer'))
const Seasons = React.lazy(() => import('./pages/Seasons'))
const Weekday = React.lazy(() => import('./pages/Weekday'))
const TrainingSchedule = React.lazy(() => import('./pages/TrainingSchedule'))
const Export = React.lazy(() => import('./pages/Export'))
const Monitor = React.lazy(() => import('./pages/Monitor'))

const publicRoutes = {
  // '/abo/:method': () => <Suspense fallback={'adfs'}><AboSuccess /></Suspense>,
  '/monitor/': () => <Monitor />,
}

const AppNew = () => {
  const [location, setLocation] = useLocation();

  const [match, params] = useRoute("/monitor/");

  useEffect(() => {
    if (!location.endsWith('/')) {
      setLocation(`${location}/`)
    }
  }, [location])



  // if (match) {
  //   return (
  //     <Suspense fallback={<CircularProgress />}>
  //       <Monitor />
  //     </Suspense>
  //   )
  // }

  return (
    <URQLProvider value={GraphQlClient}>
      <LoginContext>
        <MyselfContext>
          <Layout path={location}>
            <Suspense fallback={<CircularProgress />}>
                <Switch>
                  <Route path={`/${WEEKEND_URL_SEGMENT}/:year/:month/:day/:gymID/`} >{param => <Bookings {...param} />}</Route>
                  <Route path={`/${WEEKEND_URL_SEGMENT}/:year/:month/`}>{param => <Bookings {...param} />}</Route>
                  <Route path={`/${WEEKEND_URL_SEGMENT}/:year/`} >{param => <Bookings {...param} />}</Route>
                  <Route path={`/${WEEKEND_URL_SEGMENT}/`} >{param => <Bookings {...param} />}</Route>

                  <Route path={`/${WEEKDAY_URL_SEGMENT}/:buildingID/:roomID/liste/`} >{param => <Weekday type={'list'} {...param} />}</Route>
                  <Route path={`/${WEEKDAY_URL_SEGMENT}/:buildingID/:roomID/:year/:week/`} >{param => <Weekday {...param} />}</Route>
                  <Route path={`/${WEEKDAY_URL_SEGMENT}/:buildingID/:roomID/:year/`} >{param => <Weekday {...param} />}</Route>
                  <Route path={`/${WEEKDAY_URL_SEGMENT}/:buildingID/:roomID/`} >{param => <Weekday {...param} />}</Route>
                  <Route path={`/${WEEKDAY_URL_SEGMENT}/`} >{param => () => <Weekday {...param} />}</Route>

                  <Route path={`/trainingszeiten/:departmentID/`} >{param => <TrainingSchedule {...param} />}</Route>
                  <Route path={`/trainingszeiten/`} >{param => <TrainingSchedule {...param} />}</Route>
                  <Route path={`/trainer/:departmentID/`} >{param => <Trainer {...param} />}</Route>
                  <Route path={`/trainer/`} >{param => <Trainer {...param} />}</Route>
                  <Route path={`/anfragen/`} >{param => <RequestedBookings {...param} />}</Route>
                  <Route path={`/zeiten/:departmentID/`} >{param => <AcceptedBookings {...param} />}</Route>
                  <Route path={`/zeiten/`} >{param => <AcceptedBookings {...param} />}</Route>
                  <Route path={`/einstellungen/`} >{param => <Settings {...param} />}</Route>
                  <Route path={`/verwaltung/benutzer/`} >{param => <UserList {...param} />}</Route>
                  <Route path={`/verwaltung/saisons/`} >{param => <Seasons {...param} />}</Route>
                  <Route path={`/verwaltung/exports/`} >{param => <Export {...param} />}</Route>
                  <Route path={`/logout/`} >{param => <Logout {...param} />}</Route>,
                  <Route path={`/login/`} >{param => <Login {...param} />}</Route>,
                  <Route path={'/'}><Dashboard /></Route>
                </Switch>
            </Suspense>
          </Layout>
        </MyselfContext>
      </LoginContext>
    </URQLProvider>
  );
}

AppNew.propTypes = {}

export default AppNew;
