const hostname = window.location.hostname
let currentHost = ''
switch (hostname) {
    // live
    case 'www.hallenzeiten-tool.de':
    case 'hallenzeiten-tool.de':
    case 'www.hallenzeiten-schwarzenbek.de':
    case 'hallenzeiten-schwarzenbek.de':
        currentHost = 'https://admin.hallenzeiten-tool.de'
        break
    case 'stage.hallenzeiten-schwarzenbek.de':
        currentHost = 'https://admin.staging.hallenzeiten-tool.de'
        break
    // dev
    default:
        currentHost = 'http://hallenzeiten-tool.docker:8086'
}

// Silverstripe OAuth API
const oAuthApiEndpoint = `${currentHost}/oauth2`
export const SILVERSTRIPE_OAUTH_API = {
    urls: {
        authorize: oAuthApiEndpoint + '/token/',
        refresh: oAuthApiEndpoint + '/refresh/'
    },
    data: {
        authorize: {
            grant_type: 'password'
        },
        refresh: {
            grant_type: 'refresh_token'
        }
    }
}

export const EXPORT_ENDPOINT = {url: `${currentHost}/export`}

export const GRAPHQL_ENDPOINT = {url: `${currentHost}/graphql-app`}

export const DRAWER_WIDTH = '280px'
