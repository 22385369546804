import {makeStyles} from '@mui/styles';

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: '25px'
  },
  // gridItem: {
  //     height: '100%',
  // },
  card: {
    height: '100%',
    '& button': {
      display: 'flex',
      height: '100%',
      '& >div': {
        alignSelf: 'flex-start'
      }

    }
  },
  button: {
    height: '100%'
  },
  paper: {
    flexDirection: 'column',
    padding: '20px 0'
  },
  input: {
    width: '80%',
    margin: '10px auto'
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
}));
