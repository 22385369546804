import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Login from "../../pages/Login";
import {useLocation} from "wouter";

const LoginContext = createContext();

export const useLogin = () => useContext(LoginContext)

const LoginContextProvider = ({ children }) => {
  const [, setLocation] = useLocation();
  const [isLoggedIn, setLoggedIn] = useState(() => {
    return typeof localStorage.getItem('token') === 'string'
      && typeof localStorage.getItem('refreshToken') === 'string'
  })

  const contextValue = {
    isLoggedIn,
    setLoggedIn
  }

  useEffect(() => {
    if (!isLoggedIn) {
      setLocation('/')
    }
  }, [isLoggedIn, setLocation])

  return (
    <LoginContext.Provider value={contextValue}>
      {/*{children}*/}
      {isLoggedIn ? children : <Login />}
    </LoginContext.Provider>
  );
}

LoginContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default LoginContextProvider;
