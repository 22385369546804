import React, {useEffect} from "react";
import {useLogin} from "../features/Login/LoginContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Logout = () => {
  const { setLoggedIn } = useLogin()

  useEffect(() => {
    localStorage.clear()
    setLoggedIn(false)
    }, [setLoggedIn])

    return <Backdrop open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
}

export default Logout;
