import {cacheExchange} from "@urql/exchange-graphcache";

const cache = cacheExchange({
  keys: {
    Building: data => data.id,
    ContactPerson: data => data.id,
    Department: data => data.id,
    Member: data => data.id,
    MenuItem: data => data.id,
    Room: data => data.id,
    RoomPart: data => `${data.id}-${data.date || ''}`,
    WeekendBooking: data => data.id,
    Weekday: data => data.id,
    WeekdayRoom: data => data.id,
    WeekdayBooking: data => data.id,
  },
  optimistic: {
    updateFrontendMember: (variables, cache, info) => ({
      __typename: 'Member',
      id: variables.id,
      FirstName: variables.FirstName,
      Surname: variables.Surname,
      Email: variables.Email,
      BarrierFreeMode: variables.BarrierFreeMode,
      Active: variables.Active
    }),
    updateWeekendBooking: (variables, cache, info) => ({
      __typename: 'WeekendBooking',
      id: variables.input.id,
      TimeBegin: variables.input.TimeBegin,
      TimeEnd: variables.input.TimeEnd,
      Requested: variables.input.Requested,
    }),
    updateWeekdayBooking: (variables, cache, info) => {
      return {
        __typename: 'WeekdayBooking',
          id: variables.input.id,
        TimeBegin: `${variables.input.TimeBegin}:00`,
        TimeEnd: `${variables.input.TimeEnd}:00`,
        RoomPartID: variables.input.RoomPartID,
      }
    },
  },
  updates: {
    Mutation: {
      createWeekendBookings(result, args, cache, _info) {
        cache.invalidate({
          __typename: 'Building',
          id: args.BuildingID,
        });
      },
      deleteWeekendBookings(result, args, cache, _info) {
        if (args['ids']) {
          args['ids'].forEach(id => {
            cache.invalidate({
              __typename: 'WeekendBooking',
              id: id,
            });
          })
        }
      },
      deleteWeekdayBookings(result, args, cache, _info) {
        if (args['ids']) {
          args['ids'].forEach(id => {
            cache.invalidate({
              __typename: 'WeekdayBooking',
              id: id,
            });
          })
        }
      },
      deleteSeasons(result, args, cache, _info) {
        if (args['ids']) {
          args['ids'].forEach(id => {
            cache.invalidate({
              __typename: 'Season',
              id: id,
            });
          })
        }
      },
      deleteContactPersons(result, args, cache, _info) {
        if (args['ids']) {
          args['ids'].forEach(id => {
            cache.invalidate({
              __typename: 'ContactPerson',
              id: id,
            });
          })
        }
      },
      deleteTrainingSchedules(result, args, cache, _info) {
        if (args['ids']) {
          args['ids'].forEach(id => {
            cache.invalidate({
              __typename: 'TrainingSchedule',
              id: id,
            });
          })
        }
      },
      deleteWeekendEvents(result, args, cache, _info) {
        console.log(args)
        if (args['ids']) {
          args['ids'].forEach(id => {
            cache.invalidate({
              __typename: 'WeekendEvent',
              id: id,
            });
          })
        }
      },
    }
  }
});

export default cache;
