import {makeStyles} from '@mui/styles';
import {DRAWER_WIDTH} from "../config/config";

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  wrapper: {
    '&.MuiContainer-root': {
      paddingTop: theme.spacing(8),
    }
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    marginTop: '25px',
    [theme.breakpoints.up('md')]: {
      marginLeft: DRAWER_WIDTH
    }
  },
  contentLogin: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    marginTop: '25px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  drawer: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH})`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  nested: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(4)
  }
}));
