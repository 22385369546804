import React from 'react';
import PropTypes from 'prop-types';

// import Snackbar from './Snackbar';
import SnackbarContext from './SnackbarContext';
import {SnackbarProvider as Snackbar} from 'notistack';

const SnackbarProvider = ({ children }) => {
  return (
    <Snackbar maxSnack={10} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}>
      <SnackbarContext>
        {children}
      </SnackbarContext>
    </Snackbar>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default SnackbarProvider;
