import {SILVERSTRIPE_OAUTH_API} from "../config/config";
import convertJsonToQuery from "./ConvertJsonToQuery";

export const getToken = async (username, password) => {
  const result = await window.fetch(SILVERSTRIPE_OAUTH_API.urls.authorize, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      // 'Content-Type': 'application/json'
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'client', // no-referrer, *client
    body: convertJsonToQuery(
      Object.assign({}, SILVERSTRIPE_OAUTH_API.data.authorize, {
        username,
        password
      })
    ) // body data type must match "Content-Type" header
  })
  if (result.status === 200 || result.status === 403) {
    const fulfilledResult = await result.text()

    if (result.status === 403) {
      return {error: { message: 'Anmeldung fehlgeschlagen' }}
    }

    if (!fulfilledResult.includes('Authorization failed')) {
      return JSON.parse(fulfilledResult)
    }
  }

  return false;
}

export const refresh = async (refreshToken) => {
  const result = await window.fetch(SILVERSTRIPE_OAUTH_API.urls.refresh, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      // 'Content-Type': 'application/json'
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'client', // no-referrer, *client
    body: convertJsonToQuery(
      Object.assign({}, SILVERSTRIPE_OAUTH_API.data.refresh, {
        refresh_token: refreshToken
      })
    ) // body data type must match "Content-Type" header
  })

  if (result.status === 200) {
    return JSON.parse(await result.text())
  }

  return false;
}
