import React from 'react';
import Dashboard from '../features/Dashboard';

const DashboardPage = () => {
  return (
    <>
      <Dashboard />
    </>
  );
}

DashboardPage.propTypes = {}

export default DashboardPage;
