import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import {useLocation} from 'wouter'
import {useStyles} from '../styles'
import CalenderIcon from "@mui/icons-material/DateRange";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SettingsIcon from "@mui/icons-material/Settings";
import TrainingScheduleIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import CalendarViewWeek from "@mui/icons-material/CalendarViewWeek";
import EmojiPeople from "@mui/icons-material/EmojiPeople";
import {Person} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import config from '../../../package.json'

const icons = {
  CalenderIcon: <CalenderIcon />,
  PlaylistAddIcon: <PlaylistAddIcon />,
  PlaylistAddCheckIcon: <PlaylistAddCheckIcon />,
  SettingsIcon: <SettingsIcon />,
  TrainingScheduleIcon: <TrainingScheduleIcon />,
  CalendarViewWeekIcon: <CalendarViewWeek />,
  EmojiPeopleIcon: <EmojiPeople />,
  PersonIcon: <Person />,
}

const MenuItem = ({ item, isSmall, setMenuOpen }) => {
  const [, setLocation] = useLocation();
  const classes = useStyles()
  const [subMenuOpen, setSubMenuOpen] = useState(false)

  const handleClick = () => {
    if (item.Link && (!item.SubItems || item.SubItems.length === 0)) {
      setLocation(item.Link)
    } else {
      setSubMenuOpen(!subMenuOpen)
    }
  }

  return (
    <>
      <ListItem button key={item.ID} onClick={handleClick} className={isSmall ? classes.nested : null} secondaryAction={
        parseInt(item.Badge, 10) > 0 && (<Badge badgeContent={item.Badge} color={"primary"} overlap={"rectangular"} />)
      }>
        {item.Icon && <ListItemIcon>{icons[item.Icon]}</ListItemIcon>}
        <ListItemText
          primary={!isSmall ? item.Title : null}
          secondary={isSmall ? item.Title : null}
        />
        {item.SubItems && item.SubItems.length ? (
          subMenuOpen ? <ExpandLess /> : <ExpandMore />
        ): null}
      </ListItem>
      {item.SubItems && item.SubItems.length ? (
        <>
          {subMenuOpen && <Divider />}
          <Collapse key={`col-${item.ID}`} in={subMenuOpen} timeout="auto" unmountOnExit>
            <MenuList items={item.SubItems} isSmall={item.SmallSubItems} setMenuOpen={setMenuOpen} />
          </Collapse>
          {subMenuOpen && <Divider />}
        </>
      ) : null}
    </>
  )
}

const MenuList = ({ items = [], isRoot = false, setMenuOpen, isSmall = false }) => {
  return (
    <List disablePadding={isSmall}>
      {items.map(item => (
        // <Badge badgeContent={4} color="primary">
          <MenuItem
            key={item.Title}
            item={item}
            isSmall={isSmall}
            setMenuOpen={setMenuOpen}
          />
        // </Badge>
      ))}
    </List>
  )
}

const Version = () => {
  return (
    <Box marginTop={"auto"} paddingLeft={2}>
      <Typography variant={"subtitle2"} color={"lightgray"}>
        Version: {config.version}
      </Typography>
    </Box>
  )
}

const LayoutMenu = ({ classes, menuOpen, setMenuOpen, items }) => {
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation={'css'}>
        <Drawer
          variant={'temporary'}
          anchor={'left'}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <MenuList items={items}/>
          <Version />

        </Drawer>
      </Hidden>
      <Hidden mdDown implementation={'css'}>
        <Drawer
          classes={{paper: classes.drawerPaper}}
          variant="permanent"
          open
        >
          <div className={classes.toolbar} />
          <Divider />
          <MenuList
            items={items}
            isRoot
            setMenuOpen={setMenuOpen}
          />
          <Version />

        </Drawer>
      </Hidden>
    </nav>
  );
}

LayoutMenu.propTypes = {
  classes: PropTypes.object,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  items: PropTypes.array
}

export default LayoutMenu;
