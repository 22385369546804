import {createTheme} from '@mui/material';

// const defaultTheme = createTheme();
const theme = (customPalette = {}) => createTheme({
  palette: {
    ...customPalette
  },
  typography: {
    // htmlFontSize: 10,
    // fontFamily: 'inherit',
    // fontSize: 16,
    h1: { fontSize: 32 },
    h2: { fontSize: 26 },
    h3: { fontSize: 26 },
    h4: { fontSize: 24 },
    h5: { fontSize: 22 },
    h6: { fontSize: 20 }
  },
})

export default theme;
